/**
 * @category namespace
 */
export const namespace = (_namespace:string = 'window.console', _parent: any = window) => {
    let parts = _namespace.split('.');
    for (let i = 0, max = parts.length; i < max; i++) {
        if (!_parent[parts[i]]) _parent[parts[i]] = {};
        _parent = _parent[parts[i]];
    }
    return _parent;
}

/**
 * @category Utils
 * @description 객체가 유효한지 확인
 * @param  {Object} obj={} 탐색 대상 오브젝트
 * @param  {Array} keys=[] 키네임
 * @returns {Boolean}
 * @example
 *   objHasKeys( window, ['Twitch', 'Player'] )
 */
export const objHasKeys = (obj:any, keys: Array<string>): boolean => {
    const next: string | undefined = keys.shift();
    if (next) {
        return !!obj[next] && (!keys.length || objHasKeys(obj[next], keys));
    } else {
        return false;
    }
}

/**
 * @category Utils
 * @description 쿠키 세팅
 * @param  {String} name 쿠키이름
 * @param  {String} value 쿠키값
 * @param  {Number} expiredays 만료날짜
 * @example
 * setCookie('topBanner', 'show', 1);
 */
export const setCookie = (name: string= '', value: string = '', expiredays: number = 1, domain: string = ''): void => {
    let now: Date = new Date();
    now.setDate(now.getDate() + expiredays);
    document.cookie = `${name}=${escape(value)}; path=/; domain=${domain};expires=${now.toUTCString()};SameSite=Lax;`;
}

/**
 * @category Utils
 * @description 쿠키 확인
 * @param  {String} name 쿠키이름
 * @returns {String}
 * @example
 * getCookie('topBanner') == 'show' // true : 쿠키가 입력 되어 있는 상태
 */
export const getCookie = (name = ''): string => {
    let cookies: string = document.cookie;
    if (cookies.indexOf(name) == -1) return '';
    let cookie: string = cookies.substr(cookies.indexOf(name));
        cookie = cookie.split(';')[0];
        cookie = cookie.substr(cookie.indexOf('=') + 1);
    return cookie;
}

/**
 * @category Utils
 * @description 숫자 앞에 0 붙여주기 ex) '01', '001'
 * @param  {Number} n
 * @param  {Number} digits
 * @returns {String}
 * @example
 * leadingZeros( 3, 2 ) // return '03'
 */
export const leadingZeros = (n: number, digits: number): string => {
    let zero: string = '';
    const len: number = n.toString().length;
    let i: number = 0;
    if (len < digits) {
        for (; i < digits - len; i += 1) {
            zero += '0';
        }
    }
    return zero + n;
}

/**
 * @category Utils
 * @description URL 파라미터 키네임으로 값 가져오기
 * @param  {String} name 키네임
 * @param  {String} url 필수아님
 * @returns {String}
 * @example
 * getParamByName('query'); // return '검색어'
 * getParamByName('query', 'https://lineage.plaync.com/search/index?query=test'); // return 'test'
 */
export const getParamByName = (name: string= '', url: string= window.location.href): string | null => {
    name = name.replace(/[\[\]]/g, "\\$&");
    let regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)");
    let results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, " "));
}

/**
 * @category Utils
 * @description URL에 파라미터 추가하기
 * @param  {String} _key
 * @param  {String} _val
 * @param  {String} _url
 * @returns {String}
 * @example
 * addParam( 'page_no', '43' ),
 */
export const addParam = (_key = 'key', _val:string | number = 0, _url: string = window.location.href): string => {
    let re = new RegExp("([?&])" + _key + "=.*?(&|$)", "i");
    let separator: string = _url.indexOf('?') !== -1 ? "&" : "?";
    if (_url.match(re)) {
        return _url.replace(re, '$1' + _key + "=" + _val + '$2');
    } else {
        return _url + separator + _key + "=" + _val;
    }
}

/**
 * @category Utils
 * @description 1000 단위 콤마
 * @example
 * withCommas( 1000000 ) => '1,000,000'
 */
export const withCommas = (_num: number): string => {
    return _num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

/**
 * @category Utils
 * @description Simple object check.
 * @param item
 * @returns {boolean}
 * @example
 * isObject({a:1, b:2, c:{d:4}}) // true
 * isObject('nc.lineage2.sampleApp') // false
 */
export const isObject = <T extends unknown>(item: T): boolean => {
	return (item && typeof item === 'object' && !Array.isArray(item));
}

/**
 * @category Utils
 * @description Deep merge two objects.
 * @param target
 * @param ...sources
 * @example
 * mergeDeep({a:1}, {b:200}) // {a:1, b:200}
 */
export const mergeDeep = (target:any, ...sources:any[]): object => {
	if (!sources.length) return target;
	const source = sources.shift();

	if (isObject(target) && isObject(source)) {
		for (const key in source) {
			if (isObject(source[key])) {
				if (!target[key]) Object.assign(target, {
					[key]: {}
				});
				mergeDeep(target[key], source[key]);
			} else {
				Object.assign(target, {
					[key]: source[key]
				});
			}
		}
	}
	return mergeDeep(target, ...sources);
}

/**
 * @category Utils
 * @description hasClass
 * @example
 * const el = screen.getByTestId('hasClassTest');
 * hasClass(el, 'hasClass')
 */
export const hasClass = ( ele: HTMLElement | null, cls: string ): boolean => {
    if (ele) {
        return !!ele.className.match(new RegExp('(\\s|^)'+cls+'(\\s|$)'));
    } else {
        return false;
    }
}

/**
 * @category Utils
 * @description addClass
 * @example
 * addClass( element, 'className' );
 */
export const addClass = ( ele: Element | HTMLElement| NodeListOf<HTMLElement> | null , cls: string ): void => {
    if( !ele) return;
    if (ele instanceof NodeList) {
        ele.forEach( it=>{
            it.classList.add(cls);
        });
    }else{
        ele.classList.add(cls);
    }
}

/**
 * @category Utils
 * @description removeClass
 * @example removeClass( element, 'className' );
 */
export const removeClass = ( ele: Element | HTMLElement | NodeListOf<HTMLElement> | null, cls: string ): void => {
    if( !ele) return;
    if (ele instanceof NodeList) {
        ele.forEach( it=>{
            it.classList.remove( cls );
        });
    }else{
        ele.classList.remove( cls );
    }
}

/**
 * @category Utils
 * @description toggleClass
 * @example
 * toggleClass(document.querySelector('p.special'), 'special');
 */
export const toggleClass = (el: HTMLElement | null | undefined, className: string, force?: boolean): void => {
    if (el) {
        el.classList.toggle(className, force);
    }
}

/**
 * @category Utils
 * @description getStyle
 * @example
 * getStyle(document.querySelector('p'), 'font-size'); // '16px'
 */
export const getStyle = (el: Element, styleProp: string): string => {
    const defaultView = el.ownerDocument.defaultView;
    if (defaultView && defaultView.getComputedStyle) {
        // sanitize property name to css notation (hypen separated words eg. font-Size)
        styleProp = styleProp.replace(/([A-Z])/g, '-$1').toLowerCase();
        return defaultView.getComputedStyle(el, null).getPropertyValue(styleProp);
    }
    return '';
}

/**
 * @category Utils
 * @description URL 유효성 검사
 */
 export const isLink = ( _text: string ): boolean => {
    const pattern =   /^(http[s]?:\/\/){0,1}(www\.){0,1}[a-zA-Z0-9\.\-]+\.[a-zA-Z]{2,5}[\.]{0,1}/;
    // const pattern = /(https?:\/\/)?w{0,3}\w*?\.(\w*?\.)?\w{2,3}\S*|www\.(\w*?\.)?\w*?\.\w{2,3}\S*|(\w*?\.)?\w*?\.\w{2,3}[\/\?]\S*/;
    return pattern.test( _text );
}

/**
 * @category Utils
 * @description 한글 조사 붙이기
 * @param  {String} _text=''
 * @param  {number} _josaStyle= 0:['를','을'] 1:['로','으로'] 2:['라고','이라고']
 * @example
 * josa('사과', 1);
 * @returns {String} '사과를'
 */
export const josa = ( _text: string, _josaStyle: number ): string => {
    let code: number= _text.charCodeAt( _text.length - 1 ) - 44032;
    let josa: Array<string> = [['를','을'], ['로','으로'], ['라고','이라고']][_josaStyle];

    if ( ( code >= 0x3130 && code <= 0x318F ) || code >= 0xAC00 && code <= 0xD7A3 ) return _text+'';  // 한글이 아닐 경우

    if ( code % 28 === 0 ) {
        return _text+josa[ 0 ]; // 받침 없음
    } else {
        return _text+josa[ 1 ]; // 받침 있음
    }
}

/**
 * @category Utils
 * @description html 태그 삭제
 */
export const removeTag = ( _html:string = '' ): string => {
    return _html.replace( /<(\/)?([a-zA-Z0-9]*)(\s[a-zA-Z0-9]*=[^>]*)?(\s)*(\/)?>/ig, '' );
}

/**
 * @category Utils
 * @description escapeTag html 태그 문자열로 변환
 */
const entityMap = new Map<string, string>(Object.entries({
    "&": "&amp;",
    "<": "&lt;",
    ">": "&gt;",
    '"': '&quot;',
    "'": '&#39;',
    "/": '&#x2F;'
}))

export const escapeHtml = (_source: string) => {
    return String(_source).replace(/[&<>"'\/]/g, (s: string) => entityMap.get(s)!);
}

/**
 * @category Utils
 * @description scrollToTop
 * @example
 * scrollToTop();
 */
export const scrollToTop = ():void => {
    const c: number = document.documentElement.scrollTop || document.body.scrollTop;
        if (c > 0) {
        window.requestAnimationFrame(scrollToTop);
        window.scrollTo(0, c - c / 8);
    }
};

/**
 * @category Utils
 * @description show
 * @example
 * show(...document.querySelectorAll('img'));
 */
export const show = (...el:HTMLElement[]) => [...el].forEach(e => (e.style.display = 'unset'));

/**
 * @category Utils
 * @description hide
 * @example
 * hide(document.querySelectorAll('img'));
 */
export const hide = (el: HTMLElement | null) => {
    if(el){
        el.style.display = 'none';
    }
};

/**
 * @category Utils
 * @description insertAfter
 * @example
 * insertAfter(document.getElementById('myId'), '<p>after</p>');
 */
export const insertAfter = (el: HTMLElement, htmlString: string) => el.insertAdjacentHTML('afterend', htmlString);

/**
 * @category Utils
 * @description insertBefore
 * @example
 * insertBefore(document.getElementById('myId'), '<p>before</p>'); // <p>before</p> <div id="myId">...</div>
 */
export const insertBefore = (el: HTMLElement, htmlString: string) => el.insertAdjacentHTML('beforebegin', htmlString);

/**
 * @category Utils
 * @description @param {String} HTML representing a single element
 * @return {HTMLElement} HTMLElement
 * @example
 * var td = makeElement('<td>foo</td>'),
 * div = makeElement('<div><span>nested</span> <span>stuff</span></div>');
 */
export const makeElement = <T extends HTMLElement>(html: string): T  => {
    let tmp = document.implementation.createHTMLDocument('');
        tmp.body.innerHTML = html.trim();
    return tmp.body.firstChild as T;
}

/**
 * @category Utils
 * @description makeElements
 * @param {String} HTML representing an y number of sibling elements
 * @return {NodeList}
 * @example
 * var rows = makeElements('<tr><td>foo</td></tr><tr><td>bar</td></tr>');
 */
export const makeElements = (html: string) => {
    let tmp = document.implementation.createHTMLDocument('');
        tmp.body.innerHTML = html.trim();
    return tmp.body.childNodes;
}


/**
 * @category Utils
 * @description makeElements
 * @param {String} HTML representing an y number of sibling elements
 * @return {NodeList}
 * @example
 * const el = makeEl('a', document.body, {class: 'tclass', id: 'tid', 'name': 'tname'})
 */
export const makeEl = <K extends keyof HTMLElementTagNameMap>(tagName: K, parentEle?: HTMLElement, attr?: {[key: string]: string}, text?: string ): HTMLElementTagNameMap[K] => {
    let ele = document.createElement(tagName);
    if (parentEle) parentEle.appendChild(ele);
    if ( attr ){
        for (let key in attr) {
            ele.setAttribute(key, attr[key]);
        }
    }
    if (text) {
        ele.textContent = text;
    }
    return ele;
}


/**
 * @category Utils
 * @description uniqueID generator
 * @param {String}
 * @return {}
 */
export const uniqueID = (_prefix: string= '_'): string => {
    return _prefix + Math.random().toString(36).substring(2, 11);
}

/**
 * @category Utils
 * @description removeElement
 * @param {String}
 * @return {}
 */
export const removeElement = (_el:HTMLElement): void => {
    _el.parentNode?.removeChild(_el);
}

/**
 * @category Utils
 * @description 배열을 인자로 받아 랜덤하게 재배열 한 배열
*/
export const suffleArray = <T>(arr:Array<T>): Array<T> => {
    let idx: number = 0;
    let returnArr: Array<T> = [];
    let arrLen: number = arr.length;

    for(let i:number=0; i<arrLen; i++) {
        idx = Math.floor(Math.random()*arr.length);
        returnArr.push(arr[idx])
        arr.splice(idx,1);
    }
    return returnArr;
}

/**
 * @category Utils
 * @description 배열 range 갯수 생성
*/
export const range = (start:number, end:number, step:number = 1): Array<number> => {
	return Array.apply(0, Array(Math.ceil((end - start) / step))).map((empty:unknown, index:number) => {
        return index * step + start
    });
}

export const width = (el:HTMLElement): number => parseFloat(getComputedStyle(el, null).width.replace("px", ""));
export const height = (el:HTMLElement): number => parseFloat(getComputedStyle(el, null).height.replace("px", ""));

/**
 * @category Utils
 * @description getLang lang 값 체크
 */
export const getLang = () => {
    return document.getElementsByTagName('html')[0].getAttribute('lang');
}

/**
 * @category Utils
 * @description html 태그 문자열로 변환
 */
export const escapeTag = ( _text: string ) => {
    const htmlEscapes: {[key:string]: string} = {
        '&': '&amp;',
        '<': '&lt;',
        '>': '&gt;',
        '"': '&quot;',
        "'": '&#x27;',
        '/': '&#x2F;'
    };
    return _text.replace( /[&<>"'\/]/g, match => htmlEscapes[match] );
}


/**
 * @category Utils
 * @description show hide toggle
 */
export const toggle = ( el: HTMLElement | null, force?: boolean): void => {
    if (!el) return;

    if (force !== undefined) {
        if (force) {
            el.style.display = 'block';
        }else{
            hide(el);
        }
    }else{
        if (window.getComputedStyle(el).display === 'block') {
            hide(el);
        }else{
            el.style.display = 'block';
        }
    }
}

/**
 * @description url param
 */
export const param = ( obj: {[key: string]: string} ): string => {
    return Object.keys(obj).map((k)=>{
        return `${encodeURIComponent(k)}=${encodeURIComponent(obj[k])}`;
    }).join('&');
}

/**
 * 퍼플런처안에서 보이는 페이지인지 확인
 * 모바일퍼플 || pc 퍼플
 */
export const checkIsPurple = () => {
    return document.body.classList.contains('ncPurple');
}


export const isMobilePurpleUseragent = () => {
    /**
     * 퍼플 PC: /NGPClient/
     * 퍼플 MO: /nc(t|j)?mtalk/
     */
    const UA_PURPLES = [/nc(t|j)?mtalk/];

    return UA_PURPLES.some( targetUa => {
        return targetUa.test(window.navigator.userAgent);
    })

  }
export const isPcPurpleUseragent = () => {
    /**
     * 퍼플 PC: /NGPClient/
     * 퍼플 MO: /nc(t|j)?mtalk/
     */
    const UA_PURPLES = [/NGPClient/];

    return UA_PURPLES.some( targetUa => {
        return targetUa.test(window.navigator.userAgent);
    })
}



export const getLangForL10n = (defaultLang = 'en') => {
    const htmlLang = document.querySelector('html')!.getAttribute('lang')?.toLowerCase() || defaultLang;
    const htmlDataCountry = document.querySelector('html')!.getAttribute('data-country')?.toLowerCase();
    const userDataLocale = window.userData?.locale?.toLowerCase();

    /**
     * "스페인어-유럽" 과, "스페인어-남미" 의 언어코드가 다르다.
     * 언어는 같은데(es) locale 값이 다르고(es-ES, es-MX), locale 값에 따라 사용되는 번역이 다르다.
     * 그래서 기획과 백엔드와 프론트가 아래와 같이 협의를 진행하였다.
     *
     * 1) html lang 속성에는 기존과 같이 lang 값만 줄것이다. (스페인어 인경우 무조건 es)
     * 2) 단, es 만 가지고, l10n에 es_ES을 사용할지, ex_MX를 쓸지 모르므로,
     * 3) 처음에 cnb 에서 불러오는 userData.locale을 통해 분기를 태우고
     * 4) cnb 가 없는 페이지의 경우도 있으니,  data-country를 통해 분기를 태운다.
     *
     * [TODO] 중국어-간체 가 추가될때도 비슷하게 진행될것 같다.
     */
    if (htmlLang === 'es') {
        // 1) userData 먼저 보고
        if(userDataLocale){
            if((/-es$/i).test(userDataLocale)){
                return 'es_ES';
            }else {
                return 'es_MX';
            }
        // 2) dataCountry 보고
        }else if(htmlDataCountry){
            if((/^es$/i).test(htmlDataCountry)){
                return 'es_ES';
            }else{
                return 'es_MX';
            }
        // 3) 둘다 없으면
        }else{
            return 'es_ES';
        }
    } else {
        return htmlLang;
    }
}

/**
 * 커뮤니티 SDK 웹뷰인지 확인
 */
export const checkIsCommunitySdk = () => {
  return document.body.classList.contains('ncCommunitySDK');
}

// 현재 망 정보 
export const getNetwork = () => {
  const subdomain = {
      rc: ['rc.', 'rc-', 'rc2-'],
      sb: ['sb-', '[^.]*\.sb', 'local.', 'localhost']
  };
  let env = 'live';
  let prop: keyof typeof subdomain;
  
  for (prop in subdomain) {
      const matchs = subdomain[prop];
      const regexp = new RegExp(`^http[s]?:\\/\\/(${matchs.join('|')})+.*`, 'i');
      if (regexp.test(window.location.href)) {
          env = prop;
      }
  }

  return env;
};


export const deepMerge = (target: Record<string, any>, source: Record<string, any>): Record<string, any> => {
  if (typeof target !== 'object' || target === null || typeof source !== 'object' || source === null) return source;
  Object.keys(source).forEach(key => {
      if (source[key] && typeof source[key] === 'object') {
          if (!target[key]) target[key] = Array.isArray(source[key]) ? [] : {};
          deepMerge(target[key], source[key]);
      } else {
          target[key] = source[key];
      }
  });
  return target;
}
