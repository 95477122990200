import ar from './ar'
import en from './en'
import id from './id'
import ja from './ja'
import ko from './ko'
import ru from './ru'
import th from './th'
import zh from './zh'

import de from './de';
import fr from './fr';
import it from './it';
import es_ES from './es_ES';
import es_MX from './es_MX';
import pt from './pt';
import tr from './tr';

import hi from './hi';
import pl from './pl';

const l10n: Record<string, I18NCookieSetLayerTextData> = { ar, en, id, ja, ko, ru, th, zh, de, fr, it, es_ES, es_MX, pt, tr, hi, pl }
export default l10n
