import { getCookie, getCookies, setCookie } from '../util/util2';
import { callGetCookieData, callGetGeoLocationCountry, callGetI18NMessage, callSetApplyCookie, cookieLog, dataLayerPushUpdateConsentMode } from '../service';
import { getCountry, getLang, getLangForL10n, getNCACookieDomain, getPlayncDomain, getSeviceEnv } from '../util/util';

export default abstract class AbsGlobalCookie{
  APPLY_COOKIE = 'applyCookie';
  GEO_LOCATION_INFO = 'geoLocationInfo';
  URL_COOKIE_POLICY_DEFAULT = `${getPlayncDomain()}/policy/cookiepolicy`;
  URL_COOKIE_POLICY: string
  URL_COOKIE_POLICY_LAYER_OPEN: string

  zIndex: number;
  apiDomain: string;
  contiCookieDataApi: string;
  geoLocationCountry!: string;
  contiApiUrl: string;

  // API로 받아오는 데이터
  l10nText!: I18NCookieSetLayerTextData;
  cookieListOptionals!: CookieDataMapRequired;
  cookieListRequired!: CookieDataMapOptional;
  cookieListAll!: CookieDataMap;
  cookieVersionOptionals!: string;
  targetCountryList!: string[];

  constructor({zIndex = 1, apiDomain = '', contiCookieDataApi = ''} : {zIndex?: number, apiDomain?: string, contiCookieDataApi?: string } = {}){
    let lang = getLang({
      onlyUsingLanguageArr: [
        'ar',
        'en',
        'id',
        'ja',
        'ko',
        'th',
        'zh',
        'ru',
        'de',
        'es_ES',
        'es_MX',
        'fr',
        'it',
        'pt',
        'tr',
        'pl',
      ],
      defaultLang: 'en',
    });
    let langForL10n = getLangForL10n({
      onlyUsingLanguageArr: [
        'ar',
        'en',
        'id',
        'ja',
        'ko',
        'th',
        'zh',
        'ru',
        'de',
        'es_ES',
        'es_MX',
        'fr',
        'it',
        'pt',
        'tr',
        'pl',
      ],
      defaultLang: 'en',
    });

    this.URL_COOKIE_POLICY = lang === 'de' ? `${this.URL_COOKIE_POLICY_DEFAULT}/de` : lang === 'ja' ? `${this.URL_COOKIE_POLICY_DEFAULT}/ja` : this.URL_COOKIE_POLICY_DEFAULT;
    // this.URL_COOKIE_POLICY_LAYER_OPEN = `${this.URL_COOKIE_POLICY}?showLayer=true`;
    this.URL_COOKIE_POLICY_LAYER_OPEN = this.isPurplePcUseragent() ? `${this.URL_COOKIE_POLICY}?showLayer=true&layout=content` : `${this.URL_COOKIE_POLICY}?showLayer=true`;

    this.zIndex = zIndex;
    this.apiDomain = apiDomain;
    this.contiCookieDataApi = contiCookieDataApi;
    this.contiApiUrl = this.apiDomain + this.contiCookieDataApi


  }

  // l10n세팅
  protected async getI18nMessage(langForL10n: Lang){
    this.l10nText = await callGetI18NMessage(langForL10n);
  }

  // 콘티에서 쿠키 데이터 세팅
  protected async getCookieData(){
    const responseCookieData =  await callGetCookieData(this.contiApiUrl);

    const cookieDataOptionalsWrap = responseCookieData.find((e)=>{return e.alias === 'global-cookie-list-optionals'}) as ContiContentAPIResponse<CONTI_ALIAS_GLOBAL_COOKIE_LIST_OPTIONALS>;
    const cookieDataRequiredWrap = responseCookieData.find((e)=>{return e.alias === 'global-cookie-list-required';}) as ContiContentAPIResponse<CONTI_ALIAS_GLOBAL_COOKIE_LIST_REQUIRED>;
    const cookieDataTargetCountryListWrap = responseCookieData.find((e)=>{return e.alias === 'global-cookie-list-country';}) as ContiContentAPIResponse<CONTI_ALIAS_GLOBAL_COOKIE_LIST_COUNTRY>;

    // GDPR 대상 국가 리스트
    this.targetCountryList = JSON.parse(cookieDataTargetCountryListWrap.jsonData) as string[];

    // 쿠키 리스트
    this.cookieListOptionals = JSON.parse(cookieDataOptionalsWrap.jsonData) as CookieDataMapRequired ;
    this.cookieListRequired = JSON.parse(cookieDataRequiredWrap.jsonData) as CookieDataMapOptional;
    this.cookieListAll = {...this.cookieListOptionals, ...this.cookieListRequired};

    // 버전
    this.cookieVersionOptionals = String(cookieDataOptionalsWrap.version);

  }

  // GDPR 국가에 속하는지 여부
  protected get isNowGDPRCountry(){
    return this.targetCountryList.map(country=>country.toLowerCase()).includes(this.geoLocationCountry.toLowerCase());
  }

  // geoLocation 확인
  protected async getGeoLocationCountry(){
    const geoLocationCountry =  await callGetGeoLocationCountry();

    this.geoLocationCountry = geoLocationCountry.location;
  }

  // 필수쿠키만 저장하기
  protected applyCookieOnlyRequired(returnUrl: string){
    this.applyCookie(['NECESSARY'], returnUrl);
  }

  // 모든타입의 쿠키 저장하기
  protected applyCookieAll(returnUrl: string) {
    this.applyCookie(['NECESSARY', 'FUNCTIONAL', 'PERFORMANCE', 'ADVERTISING'], returnUrl);
  }

  // 쿠키허용상태 저장하기 클릭
  protected async applyCookie(applyCookieTypeArr: ApplyCookieType, returnUrl: string){
    const dataObj: ApplyCookieStateCookie = {
      typeArr: applyCookieTypeArr,
      optCookieListVer: this.cookieVersionOptionals,
    };

    const dataJson = JSON.stringify(dataObj);

    // applyCookie 쿠키생성
    // setCookie(this.APPLY_COOKIE, dataJson, 180, '.plaync.com');
    // setCookie(this.GEO_LOCATION_INFO, String(JSON.stringify({isGDPRCountry: this.isNowGDPRCountry})), 180, '.plaync.com');
    await this.setCookieByApi(this.APPLY_COOKIE, dataJson, 180);

    // 서버에 전송 (실패 해서 catch 되어도 cookieLog에서 throw 를 하지 않기때문에, 그냥 갈길 감 (아래로 로직 실행))
    await cookieLog(dataJson);

    // 저장하지 않은 타입의 First-party 쿠키 삭제
    this.deleteCookiesNotApplied();

    // [CSFDT-2320] 동의모드 추가
    const consentMode = this.getConsentModeFromApplyCookie(applyCookieTypeArr);
    await this.setCookieByApi('consentMode',  JSON.stringify(consentMode), 180);

    window.dataLayer = window.dataLayer || [];
    
    window.dataLayer.push({
      'event': 'ga_consent',
      'event_callback': () => console.log('ga_consent SUCCESS'),
      'event_timeout': 4000,
      ...consentMode
    });

    // await dataLayerPushUpdateConsentMode(consentMode)

    // 페이지 리로드
    setTimeout(()=> {
      location.href = returnUrl;
      setTimeout(()=>{
        // https://rc-puzzupamitoi.plaync.com/#section-2 와 같이 hash태그가 있는경우 리로드가 안되는 이슈
        location.reload();
      }, 500)
    } ,0);
  }

  // 유저가 선택한 쿠키종류에 대한, 동의모드 선택
  protected getConsentModeFromApplyCookie(applyCookieTypeArr: ApplyCookieType){
    let consentMode: ConsentModeValue = {
      analytics_storage: 'denied',
      ad_storage: 'denied',
      ad_user_data: 'denied',
      ad_personalization: 'denied'
    }

    if(applyCookieTypeArr.includes('ADVERTISING')){
      consentMode.ad_storage = 'granted'
      consentMode.ad_user_data = 'granted'
      consentMode.ad_personalization = 'granted'
    }
    
    if(applyCookieTypeArr.includes('PERFORMANCE')){
      consentMode.analytics_storage = 'granted'
    }

    return consentMode;
  }


  // 모든 타입의 쿠키를 허용한 상태인지 확인
  protected isAllCookieApply(targetTypeList: ApplyCookieType): boolean{
    return this.isListHasAllTraget(targetTypeList, ['NECESSARY', 'FUNCTIONAL', 'PERFORMANCE', 'ADVERTISING']);
  }

  // (유틸) 어떤 배열에 주어진 값이 전부 있는지 확인
  protected isListHasAllTraget(targetTypeList: ApplyCookieType, findTypeList: ApplyCookieType): boolean{
    let result = true;

    findTypeList.forEach((search)=>{
      if(!targetTypeList.includes(search)){
        result = false;
      }
    });

    return result;
  }

  /**
   * 브라우저에 저장된 쿠키중에 진짜로 제거되어야 하는 쿠키
   *
   * 브라우저에 저장된 쿠키 중에 동의 하지 않은 쿠키 -(빼기) 브라우저에 저장된 쿠키 중에 동의 하지 않은 쿠키 중에 포함된 동의 한 쿠키
   * 즉, testCookie 이름의 쿠키가 FUNCTIONAL과 PERFORMANCE에 동시에 존재한다고 했을때,
   * 한쪽 이라도 동의를 한경우, testCookie 는 살아 남는다
   */
  protected getNotAppiedCookieNames(AllCookieNamesFromConti:Record<CookieDataKey, CookieData['name'][]>, cookieTypeHasBeenApplied: ApplyCookieType) {
    let appliedCookies: string[] = [];
    let notAppliedCookies: string[] = [];
    let currentBrowserCookieName =  Object.keys(getCookies());

    appliedCookies.push(...AllCookieNamesFromConti.required);
    cookieTypeHasBeenApplied.includes('FUNCTIONAL') && appliedCookies.push(...AllCookieNamesFromConti.optional) || notAppliedCookies.push(...AllCookieNamesFromConti.optional);
    cookieTypeHasBeenApplied.includes('PERFORMANCE') && appliedCookies.push(...AllCookieNamesFromConti.optional2) || notAppliedCookies.push(...AllCookieNamesFromConti.optional2);
    cookieTypeHasBeenApplied.includes('ADVERTISING') && appliedCookies.push(...AllCookieNamesFromConti.optional3) || notAppliedCookies.push(...AllCookieNamesFromConti.optional3);

    appliedCookies = Array.from(new Set(appliedCookies)); // 콘티에서 받은 동의한 쿠키 이름 ( * 표시 포함 )
    notAppliedCookies = Array.from(new Set(notAppliedCookies)); // 콘티에서 받은 비동의한 쿠키 이름 ( * 표시 포함 )

    console.log('all Conti Cookie name: ', AllCookieNamesFromConti); // 콘티에서 넘어온 모든 쿠키
    console.log('all appliedCookies: ', appliedCookies);
    console.log('all notAppliedCookies: ', notAppliedCookies);
    console.log('all Browser Cookie name: ', currentBrowserCookieName); // 브라우저에 저장된 모든 쿠키

    // 브라우저에 저장된 쿠키 중에 동의 하지 않은 쿠키 (동의하지 않은 쿠키에 대해 * 정규식 적용)
    let targetToDeleteIncludeLive =  Array.from(new Set(this.getSelectCookieIncludedTargets(currentBrowserCookieName, notAppliedCookies)));

    // 브라우저에 저장된 쿠키 중에 동의 하지 않은 쿠키 중에 포함된 동의 한 쿠키 (동의 하지 않은 쿠기와, 동의한 쿠키에 중복으로 있는 것이 있다.) (동의한 쿠키에 대해 * 정규식 적용)
    let targetToLive = Array.from(new Set(this.getSelectCookieIncludedTargets(targetToDeleteIncludeLive, appliedCookies)));

    // 브라우저에 저장된 쿠키 중에 동의 하지 않은 쿠키 -(빼기) 브라우저에 저장된 쿠키 중에 동의 하지 않은 쿠키 중에 포함된 동의 한 쿠키
    let targetToDeleteExceptLive  = this.getarrayToRemoveArray(targetToDeleteIncludeLive, targetToLive);

    console.log('targetToDeleteIncludeLive: ', targetToDeleteIncludeLive);
    console.log('targetToLive: ', targetToLive);
    console.log('targetToDeleteExceptLive: ', targetToDeleteExceptLive);

    return targetToDeleteExceptLive;
  }

  // 현재 언어가 한국어인지 확인
  protected isKoreaLanguge(): boolean{
    return getLang().toLowerCase() === 'ko';
  }

  // 기존에 동의한 내용이 있는지 확인 (쿠키가 있는지 확인)
  protected get hasBeenAppliedCookie(): boolean{
    return !!getCookie(this.APPLY_COOKIE);
  }

  // 현재 applyCookie 라는 이름의 쿠키에 저장되어 있는, 유저가 허용한 쿠키 타입 반환
  protected getHasBeenAppliedCookieTypes(): ApplyCookieType {
    if(!this.hasBeenAppliedCookie) return [];

    let dataObj: ApplyCookieStateCookie = JSON.parse(window.decodeURIComponent(getCookie(this.APPLY_COOKIE))); // setCookie 유틸에서 자동으로 encodeURIComponent 를 실행한다.

    return dataObj.typeArr;
  }

  protected get isAppliedCookie_NECESSARY(): boolean{
    return this.getHasBeenAppliedCookieTypes().includes('NECESSARY');
  }

  protected get isAppliedCookie_FUNCTIONAL(): boolean{
    return this.getHasBeenAppliedCookieTypes().includes('FUNCTIONAL');
  }

  protected get isAppliedCookie_PERFORMANCE(): boolean{
    return this.getHasBeenAppliedCookieTypes().includes('PERFORMANCE');
  }

  protected get isAppliedCookie_ADVERTISING(): boolean{
    return this.getHasBeenAppliedCookieTypes().includes('ADVERTISING');
  }

  // 콘티에 저장된 쿠키들의 이름만 빼오기
  protected getAllCookieNamesFromConti(cookieDatas: CookieDataMap) {

    let result: Record<CookieDataKey, string[]> = {
      required: [],
      optional: [],
      optional2: [],
      optional3: []
    };

    let key: keyof CookieDataMap;
    for (key in cookieDatas){
      result[key] = cookieDatas[key].map(data => data.name);
    }

    return result;
  }

  // 유저가 허용하지 않은 타입의 쿠키 이름 반환
  protected get getCookieNamesToDelete() {
    let AllCookieNamesFromConti = this.getAllCookieNamesFromConti(this.cookieListAll); // 콘티에서 받은 모든 쿠키 이름
    let cookieTypeIsBeenApplied = this.getHasBeenAppliedCookieTypes(); // 허용한 쿠키 타입

    return this.getNotAppiedCookieNames(AllCookieNamesFromConti, cookieTypeIsBeenApplied);
  }

  // 쿠키 지우기
  protected deleteCookiesNotApplied() {

    // 지우기 메서드 실행할때마다 지워야 할 쿠키 다시 계산
    const cookieNamesToDelete = this.getCookieNamesToDelete;

    /**
     * 쿠키를 삭제 하는 로직은 setCookieByApi 를 사용하지 않고, document.cookie 를 사용해야 하는 이유.
     *
     * 1. 쿠키를 생성할때 같은 이름의 쿠키라고 하더라도, domain과 path에 따라 다른 쿠키로 존재하게 되는데, 모든 도메인과 모든 패스의 쿠키를 삭제 하기 어렵다.
     * 2. 가령 기능 쿠키 test 라는 이름의 쿠키가 www.aiononline.com 에 생성되었고, path가 /test/ 인경우
     * 3. 해당 쿠키를 완전히 삭제하는 것은 불가능하다.
     * 4. 이런 상황에서 쿠키를 지우는 로직은 사실 임시 방편이었는데,
     * 5. 이걸 api를 통해 삭제하고자 확실하지 않은 로직을 위해 너무 많은 api 호출이 예상된다.
     * 6. 또한 쿠키 생성 api에 들어가는 name 파라미터의 값은 제한 적이다. (applyCookie, geoLocationInfo)
     * 7. 따라서 NCA 도메인에 속하는 4개의 도메인에 대해 그냥 루트 패스와, 도메인을 지정해주어서 삭제하는 식으로 가야 할거 같다.
     *
     */
    cookieNamesToDelete.forEach( (name: string) => {
        document.cookie = `${name}=; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT; domain=plaync.com`; // .plaync.com 를 명시하고 만든 쿠키 && 루트 path에 해당하는 쿠키만 날림 (만들때 도메인을 명시하지 않은 경우라면)
        document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:01 GMT;`; // 현재의 도메인 && 현재의 path 에 해당 하는 쿠키만 날림 (쿠키 만들때 도메인 지정 안한경우 - 만들 당시의 도메인 = 현재의 도메인)
        document.cookie = `${name}=; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT;`; // 현재의 도메인 && 루트 path 에 해당 하는 쿠키만 날림

        // 아래 두가지 케이스에 대한 쿠키에 대해서 (/naeu, /ru)
        // IP별로 다르게 동작하는 쿠키가 필요하다고 한다.
        // lineage2m의 경우 ru와 naeu는 모두 다른 IP로 취급한다.
        // 즉, ru와 naeu 각각에서 다르게 동작하는 쿠키를 생성하기 하기 위해서는 path가 /ru/ 이거나 /naeu/ 가 필요하게 된다.

        // 도메인 값을 지정해주지 않은 이유는, /ru 패스의 경우 lineagew 와 같은 글로벌 서비스에는 포함 되어 있기 때문이다.
        // 즉 도메인을 plaync.com 으로 지정하고, /ru 패스를 지정한 쿠키를 생성하고, 나중에 해당 쿠키를 덮어 쓸경우
        // 덮어 쓴다는 의미이가, 여기서 지우는게 아니라, 예를 들면 lineage2m/ru/ 에서 쿠키를 설정 하고, 나중에 lineagw/ru/에서 쿠키를 설정하면,
        // 두 개의 쿠키는 같은 쿠키로 취급되며 충될이 날것이다.
        // 그래서 도메인 값을 지정해주지 않는다.

        // 얘를 들면 board_auto_translate 이름의 쿠키가 있는데, naeu와 ru에서 메인지정 없이 패스만 /ru/, /naeu/ 로 지정되어 쿠키가 생성된다.
        document.cookie = `${name}=; path=/naeu; expires=Thu, 01 Jan 1970 00:00:01 GMT;`; // lineage2m.plaync.com/naeu/를 위한 쿠키날림
        document.cookie = `${name}=; path=/ru ; expires=Thu, 01 Jan 1970 00:00:01 GMT;`; // lineage2m.plaync.com/ru/를 위한 쿠키날림

        // NCA
        document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:01 GMT; domain=.ncsoft.com`;
        document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:01 GMT; domain=.aiononline.com`;
        document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:01 GMT; domain=.bladeandsoul.com`;
        document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:01 GMT; domain=.lineage2.com`;

        // 쿠키 지우기 한계점
        // 1. 기본적으로 쿠키를 만들때 path를 지정하지 않은 쿠키는 지워지지 않는다. (/kr/ 또는 /community_aaaa/list/ 와 같이...)
        // 2. 도메인은 지정하지 않은채 만든 쿠키의 경우(쿠키를 만들 당시의 도메인이 저장되어 있는경우) 다른 도메인에서 이로직이 실행된다고 해서 그 쿠키가 지워지지 않는다.
        // 3. 어떤 페이지든 진입할때 마다 이 로직을 돌리지 않는이상... 다른 plaync 도메인에 있는 모든 쿠키를 지우는 것은 불가능 하다.
        //    => 진입할때 마다 지워주고 있었다. confirmToast.ts 의 init(); 에 this.deleteCookies를 사용하고 있다.
        //    => 즉 다른 plaync 도메인에 진입하면 쿠키가 지워진다. (루트 패스 혹은 진입했을때의 패스에 한정됨 (1)번 한계점은 그대로 남아 있음)

        // 참고
        // 읽을수 있다는 것과 지운다(즉, 덮어쓴다)는 다른 이야기 이다.
        // 만들때 도메인을 eTLD로(예를들면 plaync.com) 지정하고 만들면
        // plaync.com 하위 도메인에서 읽을 수 있다.
        // 단 덮어쓰기 위해서는 만들때와 동일하게 도메인을 eTLD로(예를들면 plaync.com)지정하고 덮어 써야 한다.
        // 하위 도메인에서 읽을 수 있다고, 도메인을 지정하지 않고 덮어쓰면
        // 현재의 도메인을 값으로 가지고 있는 전혀 새로운 쿠키가 생성된다.
    });
  }

  // 주어진 타켓 쿠키 이름에 대해 해당 하는 쿠키 선정
  protected getSelectCookieIncludedTargets(browserCoocookieNames: string[], targetCookieNames: string[]){

    let selectedCookieNames = targetCookieNames.reduce((acc, name)=>{
      let targetArr;

      let regex = new RegExp(`^${name.replace(/\*/g,'.*')}$`);
      targetArr = browserCoocookieNames.filter(e => regex.test(e));

      acc = acc.concat(...targetArr);

      return acc;

    }, <string[]>[]);

    selectedCookieNames = selectedCookieNames.flat(Infinity);
    return selectedCookieNames;
  }

  // 1번 배열에서, 2번 배열에 없는 요소를 제거
  protected getarrayToRemoveArray(baseArr: string[], targetArr:string[]) {
    /**
     * Set.prototype.has 는 시간 복잡도가 O(1) 이라고 함.
     *
     * 하지만...
     * 1) 배열을 Set으로 바꾸는 과정 자체도 시간이 들거든, 그래서 이미 배열이 준비되어 있고, 그 안의 원소가 별로 많지 않다면 filter와 includes를 써도 큰 차이가 없을 수 있다고 함.
     * 2) 상수 시간: O(1)이라고 해서 무조건 빠르다고 볼 수 없어. 상수 시간이 크면 큰 O(n)보다 느릴 수도 있거든.
     * 3) 최적화: 자바스크립트 엔진 같은 경우에는 내부적으로 여러 최적화를 수행하고 있어. 그래서 이론적으로 느릴 것 같은 코드가 실제로는 빠르게 동작할 수도 있지.
     * 4) 데이터 구조: Set이 메모리를 더 많이 쓸 수도 있어. 그러니까, 너의 애플리케이션에서 메모리 사용량도 고려해야 할 수 있어.
     * 5) 데이터 분포: 데이터가 어떻게 분포되어 있는지에 따라 실제 성능이 달라질 수 있어. 예를 들어, 배열이 이미 정렬되어 있다면 includes도 꽤 빠를 수 있어.
     *
     * 상수 시간: 예를 들어, 어떤 알고리즘이 1초에 처리할 수 있는 연산이 10개라고 해보자. 이건 상수 시간이지만, 다른 알고리즘이 O(n)의 시간 복잡도를 가지고 있지만 n이 작은 경우, 예를 들어 n=5일 때 0.5초면 끝난다면, O(n) 알고리즘이 더 빠르다고 볼 수 있어.
     *
     * by ChatGPT4...
     */
    const targetSet = new Set(targetArr);

    return baseArr.filter(item => !targetSet.has(item));

    /*
    return baseArr.reduce((acc, cur)=>{
      if(!targetArr.includes(cur)) {
        acc = acc.concat(cur);
      }
      return acc;

    },<string[]>[]);
    */
  }

  protected isPurpleUseragent(){
    /**
     * 퍼플 PC: /NGPClient/
     * 퍼플 MO: /nc(t|j)?mtalk/
     */
    const UA_PURPLES = [/NGPClient/, /nc(t|j)?mtalk/];

    return UA_PURPLES.some( targetUa => {
        return targetUa.test(window.navigator.userAgent);
    })
  }

  isPurpleMoUseragent(){
    /**
     * 퍼플 MO: /nc(t|j)?mtalk/
     */
    const UA_PURPLES = [/nc(t|j)?mtalk/];

    return UA_PURPLES.some( targetUa => {
        return targetUa.test(window.navigator.userAgent);
    })
  }

  isPurplePcUseragent(){
    /**
     * 퍼플 PC: /NGPClient/
     */
    const UA_PURPLES = [/NGPClient/];

    return UA_PURPLES.some( targetUa => {
        return targetUa.test(window.navigator.userAgent);
    })
  }


  // 현재 저장된 쿠키의 유효성 검사
  chkValidateCookie(){
    return {
      isValidateCookieGeoLocationInfo: this.chkValidateCookieGeoLocationInfo(),
      isValidateCookieApplyCookie:this.chkValidateCookieApplyCookie()
    }
  }

  chkValidateCookieGeoLocationInfo(){
    // geoLocation 쿠키 문자열
    let preGeoLocationInfoStr = getCookie(this.GEO_LOCATION_INFO);

    if(!!preGeoLocationInfoStr){
      let preGeoLocationInfoObj = {} as GDPRCookieStateCookie;

      try{
        // 파싱안되는 값이 있는 경우 (위에서 미리 값이 없는 경우는 걸렀으니, 값이 없는경우에ㄴ는 여기서 catch 되지는 않는다.)
        preGeoLocationInfoObj = JSON.parse(window.decodeURIComponent(preGeoLocationInfoStr));
      }catch{
        return false;
      }

      // 유효성 체크
      if(typeof preGeoLocationInfoObj.isGDPRCountry !== 'boolean' || typeof preGeoLocationInfoObj.geoLocation !== 'string'  ){
        return false;
      }else{
        return true;
      }
    }else{
      // 차라리 값이 없었다면 유효성 통과
      return true;
    }
  }

  chkValidateCookieApplyCookie(){
    // applyCookie 쿠키 문자열
    let preApplyCookieStr = getCookie(this.APPLY_COOKIE);

    if(!!preApplyCookieStr){
      let preApplyCookieObj = {} as ApplyCookieStateCookie;

      try {
        // 파싱안되는 값이 있는 경우 (위에서 미리 값이 없는 경우는 걸렀으니, 값이 없는경우에ㄴ는 여기서 catch 되지는 않는다.)
        preApplyCookieObj = JSON.parse(window.decodeURIComponent(preApplyCookieStr)); // setCookie 유틸에서 자동으로 encodeURIComponent 를 실행한다.
      } catch (err) {
        return false;
      }

      // 쿠키값 유효헝 체크
      if(!Array.isArray(preApplyCookieObj.typeArr) || typeof preApplyCookieObj.optCookieListVer !== 'string'){
        return false;
      }else{
        return true
      }
    }else{
      // 차라리 값이 없었다면 유효성 통과
      return true
    }
  }

  deleteGeoLocationInfoAndReloadPage(){
    // 쿠키 지우기
    // setCookie(this.GEO_LOCATION_INFO, '', -1, '.plaync.com');
    this.setCookieByApi(this.GEO_LOCATION_INFO, '', -1);
    // **** 강제 리로드 !! ****
    document.location.reload();
  }

  deleteApplyCookie(){
    // 쿠키 지우기
    // setCookie(this.APPLY_COOKIE, '', -1, '.plaync.com');
    this.setCookieByApi(this.APPLY_COOKIE, '', -1);
  }

  async setCookieByApi(key: string, value: string, expiredays: number){
    let todayDate = new Date();
    const expires = Math.ceil(todayDate.setDate(todayDate.getDate() + expiredays)/1000);
    const queryString = (`k=${encodeURIComponent(key)}&v=${encodeURIComponent(value)}&expires=${encodeURIComponent(expires)}`);
    const playncDomain = getPlayncDomain();
    const ncaCookieDomain = getNCACookieDomain();
    // const domain = 'https://www.sb.plaync.com';

    const paths = [
      `${playncDomain}/cookie?${queryString}`,
      `${ncaCookieDomain}.aiononline.com/?${queryString}`,
      `${ncaCookieDomain}.ncsoft.com/?${queryString}`,
      `${ncaCookieDomain}.lineage2.com/?${queryString}`,
      `${ncaCookieDomain}.bladeandsoul.com/?${queryString}`,
    ]

    try{
      // 서버에서 쿠키 굽기
      const result = await Promise.allSettled(paths.map(path=>callSetApplyCookie(path)));

      if(result.some(e => e.status === 'rejected')){
        throw new Error('some Cookie API is error');
      }else{
        return true;
      }

    }catch (err) {
      // throw err;
      console.log(err)

      // 5개도메인중 하나라도 api 호출 실패하면 (Promise.allSettled)
      // 임시로 현재 도메인 프론트 단에서 쿠키 생성함. (어떤게 실패했는지 모르니까.)
      setCookie(key, value, 180, '.plaync.com');
      setCookie(key, value, 180, '.ncsoft.com');
      setCookie(key, value, 180, '.aiononline.com');
      setCookie(key, value, 180, '.bladeandsoul.com');
      setCookie(key, value, 180, '.lineage2.com');

      return true;

    }

  }

}
